// start auto code
window.sliderTimer8 = function sliderTimer8() {
    this.setInterval(currentSlidetestimonialsauto, 5000);
}
window.addEventListener('load', () => {
    sliderTimer8();
})


window.currentSlidetestimonialsauto = function currentSlidetestimonialsauto() {
    var firstBlock = document.getElementById('first51');
    var secondBlock = document.getElementById('second51');
    var thirdBlock = document.getElementById('third51');
    var fourBlock = document.getElementById('four51');
    var fiveBlock = document.getElementById('five51');

    var oneBlock = document.getElementById('1V1');
    var twoBlock = document.getElementById('2V1');
    var threeBlock = document.getElementById('3V1');
    var foBlock = document.getElementById('4V1');
    var fiBlock = document.getElementById('5V1');

    var firstdiv = document.getElementById("swipeMyFive1").firstElementChild;
    var lastdiv = document.getElementById("swipeMyFive1").lastElementChild;
    var listtest = document.getElementById("swipeMyFive1"); // Get the <ul> element to insert a new node
    lastdiv.insertAdjacentElement("afterend", firstdiv);
    var idtest = listtest.children[0].id;

    var isPaused = false;
    if (!window.isPaused) {
        if (idtest === "first51") {
            firstBlock.style.display = 'flex';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';

            oneBlock.classList.add('dotactivetestimonials');
            twoBlock.classList.remove('dotactivetestimonials');
            threeBlock.classList.remove('dotactivetestimonials');
            foBlock.classList.remove('dotactivetestimonials');
            fiBlock.classList.remove('dotactivetestimonials');


        } else if (idtest === "second51") {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'flex';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';

            oneBlock.classList.remove('dotactivetestimonials');
            twoBlock.classList.add('dotactivetestimonials');
            threeBlock.classList.remove('dotactivetestimonials');
            foBlock.classList.remove('dotactivetestimonials');
            fiBlock.classList.remove('dotactivetestimonials');



        } else if (idtest === "third51") {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'flex';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'none';


            oneBlock.classList.remove('dotactivetestimonials');
            twoBlock.classList.remove('dotactivetestimonials');
            threeBlock.classList.add('dotactivetestimonials');
            foBlock.classList.remove('dotactivetestimonials');
            fiBlock.classList.remove('dotactivetestimonials');


        } else if (idtest === "four51") {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'flex';
            fiveBlock.style.display = 'none';


            oneBlock.classList.remove('dotactivetestimonials');
            twoBlock.classList.remove('dotactivetestimonials');
            threeBlock.classList.remove('dotactivetestimonials');
            foBlock.classList.add('dotactivetestimonials');
            fiBlock.classList.remove('dotactivetestimonials');


        } else if (idtest === "five51") {
            firstBlock.style.display = 'none';
            secondBlock.style.display = 'none';
            thirdBlock.style.display = 'none';
            fourBlock.style.display = 'none';
            fiveBlock.style.display = 'flex';


            oneBlock.classList.remove('dotactivetestimonials');
            twoBlock.classList.remove('dotactivetestimonials');
            threeBlock.classList.remove('dotactivetestimonials');
            foBlock.classList.remove('dotactivetestimonials');
            fiBlock.classList.add('dotactivetestimonials');


        }
    }
}
// end auto code section
// start manual code
window.currentSlidetestimonials = function currentSlidetestimonials(id) {
    var firstBlock = document.getElementById('first51');
    var secondBlock = document.getElementById('second51');
    var thirdBlock = document.getElementById('third51');
    var fourBlock = document.getElementById('four51');
    var fiveBlock = document.getElementById('five51');

    var oneBlock = document.getElementById('1V1');
    var twoBlock = document.getElementById('2V1');
    var threeBlock = document.getElementById('3V1');
    var foBlock = document.getElementById('4V1');
    var fiBlock = document.getElementById('5V1');

    var isPaused = false;
    window.isPaused = true;

    setTimeout(() => {
        window.isPaused = false;
    }, 15000);
    if (id === '1V1') {
        firstBlock.style.display = 'flex';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'none';
        fourBlock.style.display = 'none';
        fiveBlock.style.display = 'none';

        oneBlock.classList.add('dotactivetestimonials');
        twoBlock.classList.remove('dotactivetestimonials');
        threeBlock.classList.remove('dotactivetestimonials');
        foBlock.classList.remove('dotactivetestimonials');
        fiBlock.classList.remove('dotactivetestimonials');


    } else if (id === '2V1') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'flex';
        thirdBlock.style.display = 'none';
        fourBlock.style.display = 'none';
        fiveBlock.style.display = 'none';

        oneBlock.classList.remove('dotactivetestimonials');
        twoBlock.classList.add('dotactivetestimonials');
        threeBlock.classList.remove('dotactivetestimonials');
        foBlock.classList.remove('dotactivetestimonials');
        fiBlock.classList.remove('dotactivetestimonials');



    } else if (id === '3V1') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'flex';
        fourBlock.style.display = 'none';
        fiveBlock.style.display = 'none';


        oneBlock.classList.remove('dotactivetestimonials');
        twoBlock.classList.remove('dotactivetestimonials');
        threeBlock.classList.add('dotactivetestimonials');
        foBlock.classList.remove('dotactivetestimonials');
        fiBlock.classList.remove('dotactivetestimonials');


    } else if (id === '4V1') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'none';
        fourBlock.style.display = 'flex';
        fiveBlock.style.display = 'none';


        oneBlock.classList.remove('dotactivetestimonials');
        twoBlock.classList.remove('dotactivetestimonials');
        threeBlock.classList.remove('dotactivetestimonials');
        foBlock.classList.add('dotactivetestimonials');
        fiBlock.classList.remove('dotactivetestimonials');


    } else if (id === '5V1') {
        firstBlock.style.display = 'none';
        secondBlock.style.display = 'none';
        thirdBlock.style.display = 'none';
        fourBlock.style.display = 'none';
        fiveBlock.style.display = 'flex';


        oneBlock.classList.remove('dotactivetestimonials');
        twoBlock.classList.remove('dotactivetestimonials');
        threeBlock.classList.remove('dotactivetestimonials');
        foBlock.classList.remove('dotactivetestimonials');
        fiBlock.classList.add('dotactivetestimonials');


    }
}
// end manual code section

// start swipe code
var mySwipeeFive1 = document.getElementById('swipeMyFive1');
if (mySwipeeFive1) {
    var mySwipeeFive1 = new Hammer(mySwipeeFive1, {
        touchAction: "pan-y"
    });

    mySwipeeFive1.get('pan').set({
        direction: Hammer.DIRECTION_ALL
    });


    var idV1 = '';

    mySwipeeFive1.on("swipeleft swiperight", function (ev) {
        var firstFiveBlock = document.getElementById('first51');
        var secondFiveBlock = document.getElementById('second51');
        var thirdFiveBlock = document.getElementById('third51');
        var fourFiveBlock = document.getElementById('four51');
        var fiveFiveBlock = document.getElementById('five51');

        var oneBlockFive = document.getElementById('1V1');
        var twoBlockFive = document.getElementById('2V1');
        var threeBlockFive = document.getElementById('3V1');
        var foBlockFive = document.getElementById('4V1');
        var fiBlockFive = document.getElementById('5V1');
        // console.log("HERE :: ",ev)
        console.log("HERE :: ", ev.type)
        element = document.getElementsByClassName("dotactivetestimonials")[0];
        // element = document.querySelector('[data-type="11"]');
        console.log("My Element :: ", element)
        idV1 = element.id;
        console.log("My iD :: ", idV1)
        if (ev.type === 'swipeleft') {

            if (idV1 === '1V1') {
                firstFiveBlock.style.display = 'none';
                secondFiveBlock.style.display = 'flex';
                thirdFiveBlock.style.display = 'none';
                fourFiveBlock.style.display = 'none';
                fiveFiveBlock.style.display = 'none';

                oneBlockFive.classList.remove('dotactivetestimonials');
                twoBlockFive.classList.add('dotactivetestimonials');
                threeBlockFive.classList.remove('dotactivetestimonials');
                foBlockFive.classList.remove('dotactivetestimonials');
                fiBlockFive.classList.remove('dotactivetestimonials');



            } else if (idV1 === '2V1') {
                firstFiveBlock.style.display = 'none';
                secondFiveBlock.style.display = 'none';
                thirdFiveBlock.style.display = 'flex';
                fourFiveBlock.style.display = 'none';
                fiveFiveBlock.style.display = 'none';

                oneBlockFive.classList.remove('dotactivetestimonials');
                twoBlockFive.classList.remove('dotactivetestimonials');
                threeBlockFive.classList.add('dotactivetestimonials');
                foBlockFive.classList.remove('dotactivetestimonials');
                fiBlockFive.classList.remove('dotactivetestimonials');



            } else if (idV1 === '3V1') {
                firstFiveBlock.style.display = 'none';
                secondFiveBlock.style.display = 'none';
                thirdFiveBlock.style.display = 'none';
                fourFiveBlock.style.display = 'flex';
                fiveFiveBlock.style.display = 'none';

                oneBlockFive.classList.remove('dotactivetestimonials');
                twoBlockFive.classList.remove('dotactivetestimonials');
                threeBlockFive.classList.remove('dotactivetestimonials');
                foBlockFive.classList.add('dotactivetestimonials');
                fiBlockFive.classList.remove('dotactivetestimonials');



            } else if (idV1 === '4V1') {
                firstFiveBlock.style.display = 'none';
                secondFiveBlock.style.display = 'none';
                thirdFiveBlock.style.display = 'none';
                fourFiveBlock.style.display = 'none';
                fiveFiveBlock.style.display = 'flex';

                oneBlockFive.classList.remove('dotactivetestimonials');
                twoBlockFive.classList.remove('dotactivetestimonials');
                threeBlockFive.classList.remove('dotactivetestimonials');
                foBlockFive.classList.remove('dotactivetestimonials');
                fiBlockFive.classList.add('dotactivetestimonials');



            } else if (idV1 === '5V1') {

                firstFiveBlock.style.display = 'flex';
                secondFiveBlock.style.display = 'none';
                thirdFiveBlock.style.display = 'none';
                fourFiveBlock.style.display = 'none';
                fiveFiveBlock.style.display = 'none';

                oneBlockFive.classList.add('dotactivetestimonials');
                twoBlockFive.classList.remove('dotactivetestimonials');
                threeBlockFive.classList.remove('dotactivetestimonials');
                foBlockFive.classList.remove('dotactivetestimonials');
                fiBlockFive.classList.remove('dotactivetestimonials');



            }

        }

        if (ev.type === 'swiperight') {

            if (idV1 === '1V1') {
                firstFiveBlock.style.display = 'none';
                secondFiveBlock.style.display = 'none';
                thirdFiveBlock.style.display = 'none';
                fourFiveBlock.style.display = 'none';
                fiveFiveBlock.style.display = 'flex';

                oneBlockFive.classList.remove('dotactivetestimonials');
                twoBlockFive.classList.remove('dotactivetestimonials');
                threeBlockFive.classList.remove('dotactivetestimonials');
                foBlockFive.classList.remove('dotactivetestimonials');
                fiBlockFive.classList.add('dotactivetestimonials');




            } else if (idV1 === '2V1') {
                firstFiveBlock.style.display = 'flex';
                secondFiveBlock.style.display = 'none';
                thirdFiveBlock.style.display = 'none';
                fourFiveBlock.style.display = 'none';
                fiveFiveBlock.style.display = 'none';

                oneBlockFive.classList.add('dotactivetestimonials');
                twoBlockFive.classList.remove('dotactivetestimonials');
                threeBlockFive.classList.remove('dotactivetestimonials');
                foBlockFive.classList.remove('dotactivetestimonials');
                fiBlockFive.classList.remove('dotactivetestimonials');



            } else if (idV1 === '3V1') {
                firstFiveBlock.style.display = 'none';
                secondFiveBlock.style.display = 'flex';
                thirdFiveBlock.style.display = 'none';
                fourFiveBlock.style.display = 'none';
                fiveFiveBlock.style.display = 'none';

                oneBlockFive.classList.remove('dotactivetestimonials');
                twoBlockFive.classList.add('dotactivetestimonials');
                threeBlockFive.classList.remove('dotactivetestimonials');
                foBlockFive.classList.remove('dotactivetestimonials');
                fiBlockFive.classList.remove('dotactivetestimonials');



            } else if (idV1 === '4V1') {
                firstFiveBlock.style.display = 'none';
                secondFiveBlock.style.display = 'none';
                thirdFiveBlock.style.display = 'flex';
                fourFiveBlock.style.display = 'none';
                fiveFiveBlock.style.display = 'none';

                oneBlockFive.classList.remove('dotactivetestimonials');
                twoBlockFive.classList.remove('dotactivetestimonials');
                threeBlockFive.classList.add('dotactivetestimonials');
                foBlockFive.classList.remove('dotactivetestimonials');
                fiBlockFive.classList.remove('dotactivetestimonials');



            } else if (idV1 === '5V1') {

                firstFiveBlock.style.display = 'none';
                secondFiveBlock.style.display = 'none';
                thirdFiveBlock.style.display = 'none';
                fourFiveBlock.style.display = 'flex';
                fiveFiveBlock.style.display = 'none';

                oneBlockFive.classList.remove('dotactivetestimonials');
                twoBlockFive.classList.remove('dotactivetestimonials');
                threeBlockFive.classList.remove('dotactivetestimonials');
                foBlockFive.classList.add('dotactivetestimonials');
                fiBlockFive.classList.remove('dotactivetestimonials');



            }

        }
    });
}